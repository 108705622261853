<template>
  <div class="ant-card">
    <div class="ant-card-body">
      <div class="top">
        <div class="top_left">
          <a-button
            v-if="isShowDelay"
            icon="folder-add"
            type="primary"
            @click="batchClick"
          >
            批量延长时间
          </a-button>
        </div>
        <div class="top_right">
          <span>
            失效日期 :
            <a-range-picker @change="onChangeDate">
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </span>
          <span>
            <a-select
              style="width: 200px"
              v-model="searchForm.status"
              @change="statusChange"
            >
              <a-select-option v-for="item in statusList" :key="item.key"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </span>
          <span>
            <a-input
              v-model="searchForm.keyword"
              style="width: 200px"
              placeholder="请输入会员账号/昵称"
              @pressEnter="handleSearch"
            >
              <a-icon
                slot="prefix"
                type="search"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </span>
          <span>
            <a-button
              icon="search"
              type="primary"
              :disabled="searchDisbaled"
              @click="handleSearch"
            >
              搜索
            </a-button>
          </span>
        </div>
      </div>
      <div class="content-box">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :pagination="false"
        >
          <span slot="userName" slot-scope="userName, record">
            <span @click="userClick(record)">{{ record.userName }} </span>
          </span>
          <span slot="nickName" slot-scope="text, record" class="flexbox">
            <img v-viewer class="table-coverImg" :src="record.imageUrl" />
            <span>{{ record.nickName }}</span>
          </span>
          <span slot="status" slot-scope="text, record">
            <template v-if="record.status == 1">
              <a-badge status="success" text="生效中" class="success-text" />
            </template>
            <template v-if="record.status == 0">
              <a-badge status="error" text="已失效" class="error-text" />
            </template>
          </span>
          <span slot="action" slot-scope="text, record">
            <!-- <a
              v-if="record.status == 1"
              class="margin_right20"
              @click="handleInvalid(record)"
            >
              失效
            </a> -->
            <a
              v-if="record.status == 1 && isShowDelay"
              @click="delayClick(record)"
              >延长时间</a
            >
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination
          :count="count"
          :pageNo="searchForm.pageNo"
          @showSizeChangeFn="showSizeChangeFn"
        ></MyPagination>
      </div>
      <!-- 延长会员时间弹框 -->
      <a-modal
        title="延长会员时间"
        :visible="delayModalVisible"
        :confirmLoading="delayConfirmLoading"
        okText="确定"
        cancelText="取消"
        @ok="delayModalOk"
        @cancel="() => (delayModalVisible = false)"
      >
        <div style="margin-bottom: 20px" v-if="delayModelType == 1">
          <span>开通日期 : </span>
          <a-range-picker
            v-model="delayModalForm.openRangePicker"
            @change="onChangeModalOpen"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </div>
        <div v-if="delayModelType == 1">
          <span>失效日期 : </span>
          <a-range-picker
            v-model="delayModalForm.failureRangePicker"
            @change="onChangeModalFailure"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </div>
        <div class="banner">
          <span>为用户延长 </span>
          <a-input-number
            v-model="delayModalForm.days"
            maxLength="5"
            :min="1"
            :max="9999"
            :precision="0"
            style="width: 120px"
          />
          <span>天</span>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { Modal, message } from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import {
  MemberRecordApi,
  MemberInvalidApi,
  MemberDelayApi,
} from "@/request/api/marketingCenter";
import { codeFn } from "@/utils/tools";

export default {
  components: { MyPagination },
  created() {
    this.MemberRecordApi();
    if (codeFn("/admin/member/delay")) this.isShowDelay = true;
    if (codeFn("/admin/app/user/queryAdminUserInfos")) {
      this.isShowUserList = true;
    }
  },
  data() {
    return {
      statusList: [
        { key: "", value: "全部状态" },
        { key: 1, value: "生效中" },
        { key: 0, value: "已失效" },
      ],
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        keyword: "",
        status: "",
        memberNo: this.$route.params.memberNo,
        startTime: "",
        endTime: "",
      },
      count: 0,
      tableList: [],
      tableLoading: false,
      columns: [
        {
          title: "会员账号",
          dataIndex: "userName",
          key: "userName",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "会员头像 昵称",
          dataIndex: "nickName",
          key: "nickName",
          scopedSlots: { customRender: "nickName" },
        },
        { title: "首次开通日期", dataIndex: "createTime", key: "createTime" },
        { title: "开通日期", dataIndex: "updateTime", key: "updateTime" },
        { title: "失效日期", dataIndex: "endTime", key: "endTime" },
        {
          title: "累计付费金额（元）",
          dataIndex: "amountSum",
          key: "amountSum",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      delayModalForm: {
        openRangePicker: [],
        failureRangePicker: [],
        openArr: [],
        failureArr: [],
        keys: [],
        days: 1, // 延长模态框的天数
      },
      delayModalVisible: false, // 延长时间弹框是否显示
      delayConfirmLoading: false,
      delayModelType: 1, // 1 - 批量  2 - 单个
      searchDisbaled: false,
      isShowDelay: false,
      isShowUserList: false, // 用户管理列表
    };
  },

  methods: {
    // 批量延长时间
    batchClick() {
      this.delayModalForm.days = 1;
      this.delayModelType = 1;
      this.delayModalVisible = true;
      this.delayModalForm.openRangePicker = [];
      this.delayModalForm.failureRangePicker = [];
      this.delayModalForm.openArr = [];
      this.delayModalForm.failureArr = [];
    },
    // 搜索 - 邀请码领取状态
    statusChange(val) {
      this.searchForm.status = val;
      this.searchForm.pageNo = 1;
      this.MemberRecordApi();
    },
    // 搜索 - 选择时间
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.searchForm.startTime = dateString[0] + " 00:00:00";
        this.searchForm.endTime = dateString[1] + " 23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
    },
    // 搜索事件
    handleSearch(e) {
      this.searchDisbaled = true;
      setTimeout(() => {
        this.searchDisbaled = false;
      }, 3000);
      this.searchForm.pageNo = 1;
      this.MemberRecordApi();
    },
    // 分页切换页数
    showSizeChangeFn(pageNo, pageSize) {
      this.searchForm.pageNo = pageNo;
      this.searchForm.pageSize = pageSize;
      this.MemberRecordApi();
    },
    // 会员失效
    handleInvalid(record) {
      let that = this;
      Modal.confirm({
        title: "失效",
        content:
          "失效后，用户需要重新购买，系统不会自动退款，如有需要您可人工给用户退款，是否确定失效？",
        okType: "danger",
        onOk() {
          MemberInvalidApi({
            memberNo: that.$route.params.memberNo,
            userNo: record.userNo,
          }).then((res) => {
            if (res.code == 200) {
              that.MemberRecordApi();
              message.success("失效成功", 1);
              record.status = 0;
            }
          });
        },
      });
    },
    // 延长时间
    delayClick(record) {
      this.delayModelType = 2;
      this.delayModalForm.days = 1;
      this.delayModalForm.keys = [];
      this.delayModalForm.keys[0] = record.id;
      this.delayModalVisible = true;
    },
    // 延时开通日期改变
    onChangeModalOpen(date, dateString) {
      this.delayModalForm.openArr = dateString;
    },
    // 延时失效日期改变
    onChangeModalFailure(date, dateString) {
      this.delayModalForm.failureArr = dateString;
    },
    // 点击账号
    userClick(record) {
      if (this.isShowUserList && record.userName != "——") {
        localStorage.setItem("phone", record.userName);
        location.href = "/crm/clientManage";
      }
    },
    // 延长时间弹框确定
    delayModalOk() {
      this.delayConfirmLoading = true;
      if (
        this.delayModalForm.openRangePicker.length <= 0 &&
        this.delayModalForm.failureRangePicker.length <= 0 &&
        this.delayModelType == 1
      ) {
        message.error("请选择开通日期或者失效日期");
        this.delayConfirmLoading = false;
      } else {
        // 批量
        if (this.delayModelType == 1) {
          MemberDelayApi({
            openEndTime: this.delayModalForm.openArr[0],
            openStartTime: this.delayModalForm.openArr[1],
            invalidStartTime: this.delayModalForm.failureArr[0],
            invalidEndTime: this.delayModalForm.failureArr[1],
            days: this.delayModalForm.days,
            memberNo: this.$route.params.memberNo,
          }).then(({ code }) => {
            if (code == 200) {
              this.delayConfirmLoading = false;
              this.delayModalVisible = false;
              message.success("延长会员时间成功");
              this.MemberRecordApi();
            } else {
              this.delayConfirmLoading = false;
            }
          });
        } else if (this.delayModelType == 2) {
          // 单个
          MemberDelayApi({
            keys: this.delayModalForm.keys,
            days: this.delayModalForm.days,
            memberNo: this.$route.params.memberNo,
          }).then(({ code }) => {
            if (code == 200) {
              this.delayConfirmLoading = false;
              this.delayModalVisible = false;
              message.success("延长会员时间成功");
              this.MemberRecordApi();
            } else {
              this.delayConfirmLoading = false;
            }
          });
        }
      }
    },
    MemberRecordApi() {
      this.tableLoading = true;
      MemberRecordApi(this.searchForm).then(({ code, data }) => {
        if (code == 200) {
          this.count = data.count;
          this.tableLoading = false;
          data.data.forEach((item) => {
            item.key = item.id;
            item.updateTime = item.updateTime == null ? "——" : item.updateTime;
            item.userName = item.userName == "" ? "——" : item.userName;
          });
          this.tableList = data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_right {
    span {
      margin-right: 10px;
    }
  }
}
.invitecode-info {
  background: #ecf8fe;
  padding: 10px 20px;
  margin-bottom: 20px;
  .title {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 4px;
  }
  .label,
  .date {
    color: #9fa5af;
  }
}
.table-operator {
  margin-right: 20px;
}
.table-coverImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}
.banner {
  margin-top: 20px;
  span {
    &:nth-child(1) {
      margin-right: 10px;
    }
    &:nth-child(3) {
      margin-left: 10px;
    }
  }
}
/deep/.success-text .ant-badge-status-text {
  color: #52c41a;
}
/deep/.error-text .ant-badge-status-text {
  color: #9fa5af;
}
</style>
